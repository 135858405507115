import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID); // 추적 ID 추가

const GoogleAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; 
};

export default GoogleAnalytics;