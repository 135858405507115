import { Suspense, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LazyLoader from "./LazyLoader";

const DelaySuspense = ({ children, delay = 1000 }) => {
  const [showChildren, setShowChildren] = useState(false);
  const [visitedRoutes, setVisitedRoutes] = useState(new Set()); // 방문한 라우트를 저장
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (visitedRoutes.has(currentPath)) {
      // 이미 방문한 페이지인 경우 바로 표시
      setShowChildren(true);
    } else {
      // 방문하지 않은 페이지인 경우 최소 지연 시간 적용
      setShowChildren(false);
      const timer = setTimeout(() => {
        setVisitedRoutes((prev) => new Set(prev).add(currentPath)); // 방문한 페이지로 추가
        setShowChildren(true);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [location, delay, visitedRoutes]);

  return (
    <>
    {/* Suspens는 자식 요소가 로드되기전까지 대체 요소를 출력 (fallback) */}
    {/* 방문했던 페이지라면 최소 로드로 바로 페이지 출력  */}
      {showChildren ? (
        <Suspense fallback={<LazyLoader />}>{children}</Suspense>
      ) : (
        // 방문한 페이지가 아니였다면 LazyLoader 컴포넌를 의도적으로 1초이상 출력시켜 브랜딩효과.
        <LazyLoader />
      )}
    </>
  );
};

export default DelaySuspense;