import { useEffect } from "react";
import styles from "./TopDropdown.module.css";
import MenuItems from "./TopMenuItems";
import useTopNavStore from "../../../../store/useTopNavStore";

const Dropdown = ({
  submenus,
  dropdown,
  setDropdown,
  depthLevel,
  navLinkClickHandler,
}) => {
  const { isMenuOpen } = useTopNavStore();

  
  const primaryDropdownClass = `${styles["dropdown--on"]} ${styles["dropdown__primary"]}`;
  const secondaryDropdownClass = `${styles["dropdown--on"]} ${styles["dropdown__secondary"]}`;
  const dropdownClass =
    depthLevel > 1 ? secondaryDropdownClass : primaryDropdownClass;

  useEffect(() => {
    if (!isMenuOpen) {
      setDropdown(false); // 모든 드롭다운을 닫음
    }
  }, [isMenuOpen, setDropdown]);

  return (
    <ul className={dropdown ? dropdownClass : `${styles["dropdown--off"]}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          navLinkClickHandler={navLinkClickHandler}
          dropdown={dropdown}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
