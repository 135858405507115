import MenuItemsData from "../navItemsData"; // 네비 메뉴 항목 데이터 가져오기
import MenuItems from "./TopMenuItems";
import styles from "./TopNavBar.module.css";

const NavBar = () => {
  // dpethLevel 에 따른 CSS 변동
  const depthLevel = 0;

  // 하위 메뉴(드롭다운)이 있을경우 true로 설정,
  // 하위 메뉴들이 모두 꺼져야할 상황이 생기면 하위메뉴들이 꺼지도록 유도하는 공유전역변수 
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <nav className={styles.navbar}>
      {/* 네비게이션 메뉴 목록*/}
      <ul className={styles.navbar__menus}>
        {/* 메뉴 항목 데이터를 순회하여 MenuItems 컴포넌트를 생성 */}
        {MenuItemsData.map((menu, index) => {
          return (
            < MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })} 
      </ul>
    </nav>
  );
};

export default NavBar;
