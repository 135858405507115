import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy } from "react";
import RootLayout from "./pages/Root";
import DelaySuspense from "./components/layout/common/DelaySuspense"; // DelaySuspense 사용
import { Analytics } from "@vercel/analytics/react";
import GoogleAnalytics from "./components/layout/common/GoogleAnalytics";

import ErrorPage from "./pages/ErrorPage";

// Lazy load other pages
const HomePage = lazy(() => import("./pages/HomePage"));
const LimitedCharPage = lazy(() => import("./pages/gacha/LimitedCharPage"));
const LimitedWeaponPage = lazy(() => import("./pages/gacha/LimitedWeaponPage"));
const AlwaysCharPage = lazy(() => import("./pages/gacha/AlwaysCharPage"));
const AlwaysWeaponPage = lazy(() => import("./pages/gacha/AlwaysWeaponPage"));
const EchoPage = lazy(() => import("./pages/EchoPage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: (
          <DelaySuspense delay={1200}>
            <HomePage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/limited-char",
        element: (
          <DelaySuspense delay={1200}>
            <LimitedCharPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/limited-weapon",
        element: (
          <DelaySuspense delay={1200}>
            <LimitedWeaponPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/always-char",
        element: (
          <DelaySuspense delay={1200}>
            <AlwaysCharPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/gacha/always-weapon",
        element: (
          <DelaySuspense delay={1200}>
            <AlwaysWeaponPage />
          </DelaySuspense>
        ),
      },
      {
        path: "/echo",
        element: (
          <DelaySuspense delay={1200}>
            <EchoPage />
          </DelaySuspense>
        ),
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <>

      <RouterProvider router={router}>
      <GoogleAnalytics/>
      </RouterProvider>
      <Analytics />

    </>
  );
}

export default App;
